/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  function getMenu(){
    console.log('TEST HERE MAN')
  }
  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //wrapping with JQuery as to not alter the plugin for the posts with thumbnails
          // $( "li:eq(0) .rpwwt-post-author, li:eq(0) .rpwwt-post-date" ).wrap( "<div class='author-and-date'></div>" );
          // $( "li:eq(1) .rpwwt-post-author,li:eq(1) .rpwwt-post-date" ).wrap( "<div class='author-and-date'></div>" );
          // $( "li:eq(2) .rpwwt-post-author, li:eq(2) .rpwwt-post-date" ).wrap( "<div class='author-and-date'></div>" );
          // $( "footer.content-info section.widget").addClass("col-sm-12 col-md-4");

       console.log("INSIDE MAIN.JS yo");
       //give normal reference to jQuery
        window.$ = jQuery;
        if ('addEventListener' in document) {
          console.log('ATTACHED')
        	document.addEventListener('DOMContentLoaded', function() {
        		FastClick.attach(document.body);
        	}, false);
        }
        localStorage.setItem('cuds_Search_term', '');

        // $('.cuds-modal .close-btn').on('click', function(){
        //   $(this).parent().fadeOut();
        //   $('body').css('overflow-y','inherit');
        // });

        //Mobile Menu
        var mobileMenuOpen = false;
        $('.mobile-menu .open-close, .mobile-menu li a').on('click', function(){

          if(mobileMenuOpen === false){
            openMenu();
          }
          else{
            closeMenu();
          }
            mobileMenuOpen = !mobileMenuOpen;
        })
        function openMenu(){

      		$('.mobile-menu .circle').addClass('expand');

      		$('.mobile-menu .burger').addClass('open');
      		$('.mobile-menu .burger .x, .mobile-menu .burger .y, .mobile-menu .burger .z').addClass('spin-collapse');
      		$('.mobile-menu li').addClass('animate');

      		setTimeout(function(){
      			$('.mobile-menu .burger .y').hide();
      			$('.mobile-menu .burger .x').addClass('rotate30');
      			$('.mobile-menu .burger .z').addClass('rotate150');
      		}, 70);
      		setTimeout(function(){
      			$('.mobile-menu .burger .x').addClass('rotate45');
      			$('.mobile-menu .burger .z').addClass('rotate135');
      		}, 120);
	      }


        function closeMenu(){
      		$('.mobile-menu .burger').removeClass('open');
      		$('.mobile-menu .burger .x').removeClass('rotate45').addClass('rotate30');
      		$('.mobile-menu .burger .z').removeClass('rotate135').addClass('rotate150');
      		$('.mobile-menu .circle').removeClass('expand');
      		$('.mobile-menu li').removeClass('animate');

      		setTimeout(function(){
      			$('.mobile-menu .burger .x').removeClass('rotate30');
      			$('.mobile-menu .burger .z').removeClass('rotate150');
      		}, 50);
      		setTimeout(function(){
      			$('.mobile-menu .burger .y').show();
      			$('.mobile-menu .burger .x, .mobile-menu .burger .y, .mobile-menu .burger .z').removeClass('spin-collapse');
      		}, 70);

      	}



        //-------------------------------------------
        //Sticky header

        // $(window).scroll(function() {
        //   if(window.innerWidth >767){
        //     if ($(this).scrollTop() > 100){
        //       console.log('BOOM')
        //       $('header.banner').addClass("sticky");
        //     }
        //     else{
        //       $('header.banner').removeClass("sticky");
        //     }
        //   }
        // });
        //---------------------------


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'the-team': {
      init: function() {
        alert();
        // JavaScript to be fired on the about us page
      }
    },
    // Services  page
    'services': {
      init: function() {
        // JavaScript to be fired on the servies page
        /*Slider for the services page--------------------*/
        var $ulcontainer = $('.container-fluid.services-page #wrapper ul');
        var $panel = $('.container-fluid.services-page #wrapper ul li');
        var panelsLength = $panel.length;
        var $prev = $("button.rotator-btn.rotator-go-previous");
        var $next = $("button.rotator-btn.rotator-go-next");
        $ulcontainer.css('width',(panelsLength*110)+'%');
        var tempWidth;
        $panel.css('width',tempWidth+'px');
        //setPanelSizes();
        $(document).ready(function(){
            setPanelSizes();
        })
        $( window ).resize(function() {
          setPanelSizes();
        });

        function setPanelSizes(){
          $ulcontainer.css('width',(panelsLength*110)+'%');
          tempWidth = $('.container-fluid.services-page #wrapper').width();
          $panel.css('width',(tempWidth-5)+'px');
        //  $panel.css('width',tempWidth+'px');
        }

        var panelTimeout = setTimeout(function(){
          $panel.addClass('slideIn');
        },500);
        var point, pointStartX, pointStartY, deltaX, deltaY;
        var myScroll = new IScroll('#wrapper', {
          zoom: false,
          mouseWheel: false,
          scrollX: true,
          scrollY: false,
          snap:true,
          eventPassthrough:'vertical',
          lockDirection:true,
          momentum: false,
      });

      myScroll.on('scrollEnd', scrollEnded);

      function scrollEnded(){
        var page = myScroll.currentPage.pageX;
        var tempHeight = $('#wrapper ul li').eq(page).height();
        $ulcontainer.css('height',tempHeight+'px');
      }
        $prev.prop("disabled",true);
        myScroll.on('scrollEnd', function () {
          if(myScroll.currentPage.pageX === 0){
            $prev.prop("disabled",true);
            $next.prop("disabled",false);
          }
          else{
            $prev.prop("disabled",false);
            if(myScroll.currentPage.pageX === panelsLength-1){
              $next.prop("disabled",true);
            }
            else{
              $next.prop("disabled",false);
            }
          }
      });
      $prev.on('click', function(){
        myScroll.goToPage(myScroll.currentPage.pageX -1, 0, 500)
      });

      $next.on('click', function(){
        myScroll.goToPage(myScroll.currentPage.pageX +1, 0, 500)
      });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
